<script>
  import { params } from "@roxi/routify";
  import ShopifyEmbed from "../../components/ShopifyEmbed.svelte";
  import Locale from "../../locale/Locale.svelte";
  import { getThankYouMessage } from "../../utils";
  import { _, locale, init } from "svelte-i18n";

  const { orderID = null, online = false } = $params;
  let language = localStorage.getItem("enrol-app-locale");
  locale.set(language);
  init({
    fallbackLocale: "en",
    initialLocale: language,
  });
  let thank_you_message, price_value, firstName, lastName;
  $: {
    if (thank_you_message) {
      thank_you_message = thank_you_message
        .replaceAll("{orderID}", orderID)
        .replaceAll("{firstName}", firstName)
        .replaceAll("{lastName}", lastName);

      if (price_value) {
        thank_you_message = thank_you_message.replaceAll(
          "{price_value}",
          price_value || ""
        );
      }
    }
  }
  getThankYouMessage(orderID).then((res) => {
    if (res) {
      thank_you_message = res.price.variant.product.thank_you_message;
      firstName = res.first_name;
      lastName = res.last_name;
      price_value = res.price.value;
    }
  });
</script>

<Locale />
{#if thank_you_message}
  <div style="text-align: justify;">
    <br />
    {@html thank_you_message
      .replaceAll("{Shopify1}", "")
      .replaceAll("{shopify1}", "")}
    {#if thank_you_message.includes("{Shopify1}") || thank_you_message.includes("{shopify1}")}
      <h2>{$_("success.Add a Gwersi Storage Box to your order?")}</h2>
      <ShopifyEmbed />
    {/if}
  </div>
{:else}
  <h2>
    <strong>Success!</strong> – your Touchstone subscription order is complete.
  </h2>

  <p>
    You will soon receive an email with your payment confirmation.
    {#if orderID}
      If you do not receive an email, please check your spam folder and should
      you need to contact us, please note your Web Order No.
      <strong>{orderID}</strong>
    {:else}
      If you do not receive an email, please check your spam folder.
    {/if}
  </p>

  <p>As a member of the Order, you may wish to checkout:</p>

  <ul>
    <li>
      <a href="https://store.druidry.org/" target="_blank">Online Store</a>
    </li>
    <li>
      <a href="https://www.facebook.com/druidry/" target="_blank"
        >Tea with a Druid live broadcast (every Mon 8pm UK time)</a
      >
    </li>
    <li>
      <a href="https://www.druidry.org/library" target="_blank"
        >The online library</a
      >
    </li>
    <li>
      Listen to the Order’s podcast <a
        href="https://druidcast.libsyn.com/"
        target="_blank">Druidcast</a
      >
    </li>
  </ul>

  <p>
    Thank you for your continued connection with the Order of Bards, Ovates
    &amp; Druids.
  </p>
  <p>Yours under the oaks,</p>
  <p><strong>All of us in the OBOD office</strong></p>

  {#if !online}
    <hr />

    <h2>Add a Gwersi Storage Box to your order?</h2>

    <ShopifyEmbed />
  {/if}
{/if}
<div class="btn-container">
  <a class="btn btn--primary" href={$_("success.back_to_website_url")}
    >{$_("success.Back to the OBOD website")}</a
  >
</div>

<style>
  p,
  ul {
    margin: 0.5em 0;
  }

  p {
    text-align: justify;
  }

  li {
    margin-left: 1em;
    font-style: italic;
  }

  hr {
    margin: 1rem 0;
  }
</style>
